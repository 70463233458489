import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import ui, { InitialStateTypes as UiInitialStateTypes } from './ui';

export interface InitialStateTypes {
    ui: UiInitialStateTypes;
}

export const store = configureStore({
    reducer: {
        ui: ui.reducer,
    },
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// expose store when run in Cypress
if (typeof window !== 'undefined' && window?.Cypress) {
    window.store = store;
}
