const breakpoints = () => ({
    values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1536,
        xxxl: 2000,
    },
});

export default breakpoints;
